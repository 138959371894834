@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Poor+Story&display=swap');

body {
  margin: 0;
  background-color: #f3edeb;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Anton", "Bebas Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


h3 {
  width: auto;
  font-family: "Anton";
  display: flex;
  /* font-weight: bolder;
  background-color:  #c85c3b; */
  text-align: center;
  color:#FFB800;
  letter-spacing: 0.1em;
  padding: 5px 20px 5px 20px;
  font-size: 20px;
  margin: 25px 0px 15px 0px; 
  text-transform: uppercase;
  font-weight: lighter;
}


h4 {
  font-family: "Anton";
  font-weight: lighter;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  font-size: 18px;
  color:#FFB800;
  letter-spacing: 0.1em;
  text-transform: uppercase;

}


h5 {
  font-weight: bold;
  font-family: "Bebas Neue";
  color: #FFB800;
  /* line-height: 20px;  */
  font-size: 20px;
  margin: 10px 0 0 10px; 
  text-transform: uppercase;
}

p {
 
  /* border: solid red 2px; */
  color: black;   
  line-height: 30px; 
  font-size: 16px;
  /* display: flex; */
  text-align: center;
}


/* .curve {

  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: #f3edeb;
}

.curve::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #FFB800;
  transform: translate(-4%, 40%);
  z-index: -1;
} */

/* 
.heart {
  position: relative;
  background-color: #F2DEB8FF;
  display: inline-block;
  height: 15px;
  top:10px;
  left:0;
  transform: rotate(-45deg);
  width:15px;
}
  
.heart:before, .heart:after {
  content:"";
  background-color: #F2DEB8FF;
  border-radius:50%;
  height: 15px;
  width: 15px;
  position: absolute;

  }
  
.heart:before {
  top:-10px;
  left:0;}
  
.heart:after {
  left:10px;
  top:0;} */


  @media (min-width: 668px) {
h4{
    font-size: 26px;
    }

  h3{
      font-size: 25px;
    }

    p {
      font-size: 20px;

    }
    
  }
    @media (min-width: 1024px) {

      h4{
        font-size: 28px;
        }

        h3{
          font-size: 25px;
        }
    
        p {
          font-size: 20px;
    
        }
        
      }
      

b {
  font-weight: bolder;
  color: #FFB800;
  font-family: "Roboto";
  letter-spacing: 0.1em;
  font-size: 18px;
}


a {

  text-decoration: none;  
}
